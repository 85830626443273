import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseConfirmDialogComponent } from '@core/components/confirm-dialog/confirm-dialog.component';
import { VerifyInfoDialogComponent } from '@core/components/xpro-verify-account/verify-info-dialog.component';
import { FuseIfOnDomDirective } from '@core/directives/fuse-if-on-dom/fuse-if-on-dom.directive';
import {
  FuseMatSidenavHelperDirective,
  FuseMatSidenavTogglerDirective,
} from '@core/directives/fuse-mat-sidenav-helper/fuse-mat-sidenav-helper.directive';
import { FuseMatSidenavHelperService } from '@core/directives/fuse-mat-sidenav-helper/fuse-mat-sidenav-helper.service';
import { FusePerfectScrollbarDirective } from '@core/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { UploaderDirective } from '@core/directives/uploader.directive';
import { MaterialModule } from '@core/modules/material.module';
import { FusePipesModule } from '@core/pipes/pipes.module';
import { FuseMatchMedia } from '@core/services/match-media.service';
import { FuseTranslationLoaderService } from '@core/services/translation-loader.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { FuseNavbarVerticalService } from '../../apps/components/navbar/vertical/navbar-vertical.service';

@NgModule({
  declarations: [
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FuseConfirmDialogComponent,
    FuseIfOnDomDirective,
    FusePerfectScrollbarDirective,
    VerifyInfoDialogComponent,
    UploaderDirective,
  ],
  imports: [
    FlexLayoutModule,
    DropzoneModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    FusePipesModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ChartsModule,
    NgxChartsModule,
  ],
  exports: [
    FlexLayoutModule,
    DropzoneModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FusePipesModule,
    FusePerfectScrollbarDirective,
    ReactiveFormsModule,
    NgxDatatableModule,
    FuseIfOnDomDirective,
    TranslateModule,
    ChartsModule,
    NgxChartsModule,
    UploaderDirective,
  ],
  entryComponents: [FuseConfirmDialogComponent, VerifyInfoDialogComponent],
  providers: [
    CookieService,
    FuseMatchMedia,
    FuseNavbarVerticalService,
    FuseMatSidenavHelperService,
    FuseTranslationLoaderService,
  ],
})
export class SharedModule {}
