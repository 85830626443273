import { Logger } from 'aws-amplify';
import { Subject } from 'rxjs';

const logger = new Logger('base.component');

export class BaseComponent {
  public errorMessage: any;

  public unsubscribeAll$: Subject<any>;

  constructor() {
    this.unsubscribeAll$ = new Subject();
  }

  clearError(): void {
    this.errorMessage = null;
  }

  displayError(error: any): void {
    logger.error(error);
    this.errorMessage = error.message;
  }

  ngOnDestroy() {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }
}
