import { Injectable } from '@angular/core';

@Injectable()
export class FuseNavbarVerticalService {
  public navBarRef;

  constructor() {}

  public setNavBar(ref) {
    this.navBarRef = ref;
  }

  public getNavBar() {
    return this.navBarRef;
  }
}
