import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FileAction } from '@core/models/xpro-fileAction.model';

@Injectable()
export class FileUploadService {
  public fileUploadSubject = new Subject<FileAction>();
  constructor() {}

  public publishFileAction(uploadedFile: FileAction) {
    this.fileUploadSubject.next(uploadedFile);
  }
}
