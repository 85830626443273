import { ClientContext } from '@core/models/client-context.model';

export class EmailConfigSetting {
  sender: string;
  subject: string;
  attachments: string[];
}

export class EmailConfig {
  clientClaimReminder: EmailConfigSetting;
  clientUpdateReminder: EmailConfigSetting;
  clientWelcome: EmailConfigSetting;
  vendorClaimCreate: EmailConfigSetting;
  vendorClaimReminder: EmailConfigSetting;
  vendorUpdateReminder: EmailConfigSetting;
  vendorWelcome: EmailConfigSetting;
  vendorEmailUpdate: EmailConfigSetting;
  userLoginReminder: EmailConfigSetting;

  constructor() {
    this.clientClaimReminder = new EmailConfigSetting();
    this.clientUpdateReminder = new EmailConfigSetting();
    this.clientWelcome = new EmailConfigSetting();
    this.vendorClaimCreate = new EmailConfigSetting();
    this.vendorClaimReminder = new EmailConfigSetting();
    this.vendorUpdateReminder = new EmailConfigSetting();
    this.vendorWelcome = new EmailConfigSetting();
    this.vendorEmailUpdate = new EmailConfigSetting();
    this.userLoginReminder = new EmailConfigSetting();
  }
}

export type Client = ClientContext & {
  emailConfig: EmailConfig;
};
