import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ClientContext } from '@core/models/client-context.model';
import { ClientService } from '@core/services/client.service';
import { Logger } from 'aws-amplify';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const logger = new Logger('apps.resolver');

@Injectable()
export class AppResolver implements Resolve<any> {
  constructor(private http: HttpClient, private clientService: ClientService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ClientContext> {
    const hostname = window.location.hostname;
    return this.clientService
      .getClientBySubdomain(hostname)
      .pipe(tap(context => this.clientService.setClientContext(context)));
  }
}
