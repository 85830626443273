<form name="profileForm" class="profile-form" (ngSubmit)="onSubmit()" [formGroup]="profileForm" novalidate>
  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="please-verify-text">
      <span>
        Please verify the following information
      </span>
      <span class="secondary-text">
        Once everything has been filled out, dismiss this screen by pressing SUBMIT at the bottom of the window.
      </span>
    </div>
  </div>


  <div class="profile">
    <mat-card class="profile-user-edit">
      <mat-card-header>
        <mat-card-title>User Profile</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div *ngIf="editMode; else profileTemplate" fxLayout="row" fxLayoutAlign="space-around start">
          <div class="details">
            <div class="input-wrapper">
              <mat-form-field class="edit-input">
                <input
                  matInput
                  placeholder="First Name"
                  formControlName="firstName"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="given-name"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('firstName').getError('required')">
                  Please enter your first name
                </mat-error>
              </mat-form-field>
            </div>
            <div class="input-wrapper">
              <mat-form-field class="edit-input">
                <input
                  matInput
                  placeholder="Last Name"
                  formControlName="lastName"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="family-name"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('lastName').getError('required')">
                  Please enter your last name
                </mat-error>
              </mat-form-field>
            </div>

            <div class="input-wrapper">
              <mat-form-field class="edit-input">
                <input
                  matInput
                  placeholder="Email"
                  formControlName="email"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="email"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('email').getError('required')">
                  Please enter your email
                </mat-error>
                <mat-error
                  *ngIf="profileForm.get('email').getError('email') && !profileForm.get('email').getError('required')"
                >
                  Please enter a valid email address
                </mat-error>
              </mat-form-field>
            </div>

            <div class="input-wrapper">
              <mat-form-field class="edit-input">
                <span matPrefix>+1 &nbsp;</span>
                <input
                  matInput
                  type="tel"
                  placeholder="Phone Number"
                  formControlName="phoneNumber"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="tel-national"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('phoneNumber').getError('required')">
                  Please enter your Phone Number
                </mat-error>
              </mat-form-field>
            </div>

            <div class="input-wrapper">
              <mat-form-field class="edit-input">
                <span matPrefix>+1 &nbsp;</span>
                <input
                  matInput
                  placeholder="Fax Number"
                  formControlName="faxNumber"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="fax-national"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('faxNumber').getError('required')">
                  Please enter your Fax Number
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="details">
            <div class="input-wrapper">
              <mat-form-field class="edit-input">
                <input
                  matInput
                  placeholder="Company Name"
                  formControlName="companyName"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="organization"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('companyName').getError('required')">
                  Please enter your Company Name
                </mat-error>
              </mat-form-field>
            </div>

            <!-- <div>
                            <mat-form-field class="edit-input">
                                <input matInput placeholder="Vendor Number" formControlName="vendorNumbers" autocorrect="off" spellcheck="false" autocapitalize="off" autocomplete="off" tabindex="0">
                                <mat-error *ngIf="profileForm.get('vendorNumbers').getError('required')">
                                    Please enter your Vendor Number 
                                </mat-error>
                            </mat-form-field>   
                        </div> -->

            <div class="input-wrapper">
              <mat-form-field class="edit-input">
                <input
                  matInput
                  placeholder="Street Address"
                  formControlName="streetAddress"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="street-address"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('streetAddress').getError('required')">
                  Please enter your Street Address
                </mat-error>
              </mat-form-field>
            </div>

            <div class="input-wrapper">
              <mat-form-field class="city">
                <input
                  matInput
                  placeholder="City"
                  formControlName="city"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="address-level2"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('city').getError('required')">
                  Please enter your City
                </mat-error>
              </mat-form-field>

              <mat-form-field class="state">
                <input
                  matInput
                  placeholder="State"
                  formControlName="state"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="address-level1"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('state').getError('required')">
                  Please enter your State
                </mat-error>
              </mat-form-field>
            </div>

            <div class="input-wrapper">
              <mat-form-field class="country">
                <input
                  matInput
                  placeholder="Postal Code"
                  formControlName="postal"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="postal-code"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('postal').getError('required')">
                  Please enter your Postal Code
                </mat-error>
              </mat-form-field>
              <mat-form-field class="country">
                <input
                  matInput
                  placeholder="Country"
                  formControlName="country"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="off"
                  autocomplete="country"
                  tabindex="0"
                />
                <mat-error *ngIf="profileForm.get('country').getError('required')">
                  Please enter your Country
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div>
          <mat-form-field floatLabel="always" class="checkbox-form-field" *ngIf="role === 'Vendor'">
            <div class="vendor-number-repeat-wrapper">
              <div class="vendor-number" *ngFor="let number of vendorNumberCheckBox">
                <mat-checkbox (change)="onChangeOfVendorNumber(number, $event)">{{ number }}</mat-checkbox>
              </div>
            </div>
            <div id="missing-vendor-numbers">
              <mat-checkbox
                [(ngModel)]="vendorNumberMissing"
                (change)="vendorNumberMissingChangeHandler($event)"
                [checked]="vendorNumberMissing && verifiedVendorNumbers.length < 1"
                [disabled]="verifiedVendorNumbers.length > 0"
                [ngModelOptions]="{ standalone: true }"
                matTooltip="Select this option if you have one or more vendor numbers that are not shown in the list."
                matTooltipPosition="right"
                matTooltipHideDelay="1000"
                >Other</mat-checkbox
              >
            </div>
            <input
              formControlName="verifiedVendorNumbers"
              placeholder="Vendor Numbers"
              style="height: 0; pointer-events: none;"
              readonly
              matInput
            />
            <mat-error *ngIf="profileForm.get('verifiedVendorNumbers').getError('required')">
              Please select your vendor number(s)
            </mat-error>
          </mat-form-field>
        </div>
        <!-- ngIf Edit Mode-->

        <ng-template #profileTemplate>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <div>
              <div class="title mb-24">
                <mat-error *ngIf="errorMessage"> Error: {{ errorMessage }} </mat-error>
              </div>
              <div class="edit-label">First Name</div>
              <div class="edit-value">{{ user.firstName }}</div>

              <div class="edit-label">Last Name</div>
              <div class="edit-value">{{ user.lastName }}</div>

              <div class="edit-label">Email</div>
              <div class="edit-value">{{ user.email }}</div>

              <div class="edit-label">Phone</div>
              <div class="edit-value">{{ user.phone }}</div>
            </div>

            <div>
              <div class="title mb-24">
                <mat-error *ngIf="errorMessage"> Error: {{ errorMessage }} </mat-error>
              </div>
              <div class="edit-label">Company Name</div>
              <div class="edit-value">{{ user.company }}</div>

              <div class="edit-label">Vendor Numbers</div>
              <div class="edit-value">{{ user.vendorNumbers }}</div>

              <div class="edit-label">Address</div>
              <div class="edit-value">{{ user.address }}</div>

              <div class="edit-label">Fax Number</div>
              <div class="edit-value">{{ user.fax }}</div>
            </div>
          </div>
        </ng-template>
        <!-- /NEW CODE -->
      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
        <div>
          <button mat-raised-button color="primary" [disabled]="!this.profileForm.valid" type="submit">SUBMIT</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</form>
