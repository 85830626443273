import { NgModule } from '@angular/core';
import { CamelCaseToDashPipe } from '@core/pipes/camelCaseToDash.pipe';
import { DashboardNumber } from '@core/pipes/dashboardNumber.pipe';
import { DaysSinceDatePipe } from '@core/pipes/daysSinceDate.pipe';
import { FilenameToIcon } from '@core/pipes/fileNameToIcon.pipe';
import { FilterPipe } from '@core/pipes/filter.pipe';
import { GetByIdPipe } from '@core/pipes/getById.pipe';
import { HtmlToPlaintextPipe } from '@core/pipes/htmlToPlaintext.pipe';
import { KeysPipe } from '@core/pipes/keys.pipe';
import { ObjectPropertyPipe } from '@core/pipes/objectProperty.pipe';
import { PageSizeOptionsPipe } from '@core/pipes/pageSizeOptions.pipe';
import { PhoneNumberPipe } from '@core/pipes/phoneNumber.pipe';
import { EINPipe } from './einPipe.pipe';
import { FilenameTruncationPipe } from './filenameTruncation.pipe';
import { FileFromUrlPipe } from './fileFromUrl.pipe';
import { ListPipe } from './list.pipe';
import { SafePipe } from './safePipe.pipe';
import { TimestampPipe } from './timestamp.pipe';
import { StateCountryAbbrevPipe } from './state-country-abbrev.pipe';
import { MarkdownToHtmlPipe } from './markdown-to-html.pipe';

@NgModule({
  declarations: [
    CamelCaseToDashPipe,
    DashboardNumber,
    DaysSinceDatePipe,
    EINPipe,
    FilenameToIcon,
    FilenameTruncationPipe,
    FileFromUrlPipe,
    FilterPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    KeysPipe,
    ListPipe,
    ObjectPropertyPipe,
    PageSizeOptionsPipe,
    PhoneNumberPipe,
    SafePipe,
    StateCountryAbbrevPipe,
    TimestampPipe,
    MarkdownToHtmlPipe,
  ],
  imports: [],
  exports: [
    CamelCaseToDashPipe,
    DashboardNumber,
    DaysSinceDatePipe,
    EINPipe,
    FilenameToIcon,
    FilenameTruncationPipe,
    FileFromUrlPipe,
    FilterPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    KeysPipe,
    ListPipe,
    ObjectPropertyPipe,
    PageSizeOptionsPipe,
    PhoneNumberPipe,
    SafePipe,
    StateCountryAbbrevPipe,
    TimestampPipe,
    MarkdownToHtmlPipe,
  ],
})
export class FusePipesModule {}
