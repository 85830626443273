import { XproEntity } from '@core/models/xpro-entity.model';
import { throwError as observableThrowError } from 'rxjs';

export class BaseService {
  protected handleError(error: Response) {
    return observableThrowError(error);
  }

  protected injectFileDownload(filename: string, fileBytes: Uint8Array, fileType: string) {
    const fileBlob = new Blob([fileBytes], { type: fileType });
    const downloadURL = window.URL.createObjectURL(fileBlob);
    const downloadAnchor = document.createElement('a');
    downloadAnchor.href = downloadURL;
    downloadAnchor.hidden = true;
    document.body.appendChild(downloadAnchor);
    downloadAnchor.download = filename;
    downloadAnchor.click();
    window.URL.revokeObjectURL(downloadURL);
    document.body.removeChild(downloadAnchor);
  }

  getHeaders(xproEntity: XproEntity) {
    let headers = {};
    if (xproEntity && xproEntity.clientId) {
      headers = this.getClientIdHeader(xproEntity.clientId);
    }
    return { headers };
  }

  skipAuthorizationHeaders = () => ({ headers: { ['X-Skip-Auth']: '' } });

  getClientIdHeader(clientId?: string) {
    if (clientId) {
      return { 'xpro-client': clientId };
    }
    return {};
  }
}

export type XProResponse<T> = {
  code: number;
  data: T;
};

export type XProError = {
  headers: {
    normalizedNames: any;
    lazyUpdate: any;
  };
  status: number;
  statusText: string;
  url: string;
  ok: boolean;
  name: string;
  message: string;
  error: {
    code: number;
    message: string;
  };
};
