import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientContext } from '@core/models/client-context.model';
import { BaseService, XProResponse } from '@core/services/base.service';
import { ClientService } from '@core/services/client.service';
import { Logger, Storage } from 'aws-amplify';
import { environment } from 'environments/environment';
import { from, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

const logger = new Logger('storage.service');
const BUCKET_URL_PREFIX = environment.aws.Storage.bucket;
const API_URL = `${environment.endpointPath}/files`;

const profileDefaults = {
  profileImagePath: 'assets/images/avatars/profile.jpg',
};

@Injectable()
export class StorageService extends BaseService {
  public clientContext: ClientContext;
  public s3Options: any;
  optionsMap: Map<string, any>;
  onClientContextChanged: Subscription;

  constructor(private http: HttpClient, private clientService: ClientService) {
    super();

    this.clientService.onChanged.subscribe((data: ClientContext) => {
      if (data) {
        this.clientContext = data;
        this.s3Options = {
          customPrefix: {
            public: `${data.id}/public/`,
            protected: `${data.id}/protected/`,
            private: `${data.id}/private/`,
          },
        };
        logger.info(`Set s3Options for StorageService: ${JSON.stringify(this.s3Options)}`);
      }
    });

    this.clientService.selectedContext.subscribe(data => {
      if (!data) return;
      const { id, children } = data;
      const keys = [id, ...(children || []).map(c => c.id)];
      const kv = <[string, any][]>keys.map(id => [
        id,
        {
          customPrefix: { public: `${id}/public/`, protected: `${id}/protected/`, private: `${id}/private/` },
        },
      ]);
      this.optionsMap = new Map(kv);
    });
  }

  getBucketOptions(clientId?: string): any {
    let clientOptions = this.optionsMap.get(clientId);

    if (!clientOptions && clientId) {
      clientOptions = {
        customPrefix: {
          public: `${clientId}/public/`,
          protected: `${clientId}/protected/`,
          private: `${clientId}/private/`,
        },
      };
    }
    const options = clientOptions || this.s3Options;
    logger.info('S3Options', options);
    return options;
  }

  put(key: string, obj: any, options?: any, clientId?: string): Promise<Object> {
    const newOptions = { ...(options || {}), ...this.getBucketOptions(clientId) };
    logger.info(`Putting ${key} with options ${JSON.stringify(newOptions)}`);
    return Storage.put(key, obj, newOptions);
  }

  get(key: string, options?: any, clientId?: string): Promise<Object> {
    const newOptions = { ...(options || {}), ...this.getBucketOptions(clientId) };
    logger.info(`Getting ${key} with options ${JSON.stringify(newOptions)}`);
    return Storage.get(key, newOptions);
  }

  remove(key: string, options?: any, clientId?: string): Promise<Object> {
    const newOptions = { ...(options || {}), ...this.getBucketOptions(clientId) };
    logger.info(`Removing ${key} with options ${JSON.stringify(newOptions)}`);
    return Storage.remove(key, newOptions);
  }

  getPublicPath(key: string, clientId?: string): string {
    const id = clientId || this.clientContext.id;
    return `https://${BUCKET_URL_PREFIX}.s3.amazonaws.com/${id}/public/${key}`;
  }

  getRootPublicPath(key: string): string {
    return `https://${BUCKET_URL_PREFIX}.s3.amazonaws.com/public/${key}`;
  }

  getPresignedUrl(url: string) {
    return this.http.post<XProResponse<string>>(`${API_URL}/presigned`, { url }).pipe(
      mergeMap(res => {
        return this.http.get(res.data, { responseType: 'blob', headers: { 'X-Skip-Interceptor': '' } });
      })
    );
  }

  downloadFile(url: string, target: HTMLAnchorElement): void {
    const key = url.split(/s3\.amazonaws.com\/.*(?:public|private|protected)\//).slice(-1)[0];
    const filename = (key.split('/') || ['']).slice(-1)[0];
    from(this.get(key, { level: 'public', expires: 100 }, this.clientContext.id))
      .pipe(mergeMap((res: string) => this.http.get(res, { responseType: 'blob' })))
      .subscribe((blob: Blob) => {
        this.clickTarget(target, blob, filename);
      });
  }

  downloadPresignedFile(url: string, target: HTMLAnchorElement, filename: string) {
    this.getPresignedUrl(url).subscribe(blob => {
      this.clickTarget(target, blob, filename);
    });
  }

  clickTarget(target: HTMLAnchorElement, blob: Blob, filename: string): void {
    // @ts-ignore
    const url = URL.createObjectURL(blob, { oneTimeOnly: true });
    target.setAttribute('href', url);
    target.download = filename;
    target.click();
    target.removeAttribute('href');
    target.removeAttribute('download');
  }
}
