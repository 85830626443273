export const locale = {
  lang: 'en',
  data: {
    NAV: {
      DASHBOARDS: 'DASHBOARDS',
      OVERVIEW: {
        TITLE: 'Overview',
      },
      ONBOARDING: {
        TITLE: 'Onboarding',
        POTENTIAL: 'Company Information',
      },
      ROOTCAUSE: {
        TITLE: 'Root Cause Analysis',
      },
      STATEMENTS: 'STATEMENTS',
      STATEMENTUPLOAD: {
        TITLE: 'Statement Uploads',
      },
      STATEMENTCOLLECTED: {
        TITLE: 'Collected',
      },
      STATEMENTOUTSTANDING: {
        TITLE: 'Outstanding',
      },
      CLAIMS: 'CLAIMS',
      CLIENTS: {
        TITLE: 'Clients',
      },
      OPENCLAIMS: {
        TITLE: 'Open Claims',
        BADGE: 'new',
      },
      INDISCUSSIONCLAIMS: {
        TITLE: 'In Discussion Claims',
        BADGE: 'new',
      },
      CLOSEDCLAIMS: {
        TITLE: 'Closed Claims',
      },
      PROFILE: 'PROFILE',
      COMPANY: {
        TITLE: 'Company',
      },
      USER: {
        TITLE: 'User',
      },
      USERS: {
        TITLE: 'Users',
      },
      VENDORS: {
        AP: {
          TITLE: 'AP',
        },
        TITLE: 'Vendors',
        VENDORMASTER: {
          TITLE: 'Vendor Master',
        },
        REJECTEDVENDORS: {
          TITLE: 'Rejected Vendors',
        },
        APPROVAL_LIST: {
          TITLE: 'Approval List',
        },
      },
      XPRO_VERIFICATION: {
        TITLE: 'XPRO Verification',
      },
      VERIFICATION_REQUESTS: {
        TITLE: 'Verification Requests',
      },
      XPRO_MANAGEMENT: {
        TITLE: 'XPRO Management',
      },
      CLIENTSETTINGS: {
        TITLE: 'Client Settings',
        GENERAL: {
          TITLE: 'General Info',
        },
      },
      INVOICES: {
        TITLE: 'Invoices',
      },
    },
  },
};
