const ɵ0 = {
    NAV: {
        APPLICATIONS: 'Programlar',
        SAMPLE: {
            TITLE: 'Örnek',
            BADGE: '15',
        },
    },
};
export const locale = {
    lang: 'tr',
    data: ɵ0,
};
export { ɵ0 };
