import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@core/models/user';
import { BaseService } from '@core/services/base.service';
import { StorageService } from '@core/services/storage.service';
import { UserService } from '@core/services/user.service';
import * as Sentry from '@sentry/browser';
import { Logger } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthState } from 'aws-amplify-angular/src/providers/auth.state';
import { environment } from 'environments/environment';
import { BehaviorSubject, from as observableFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const BUCKET_URL_PREFIX = environment.aws.Storage.bucket;
const API_URL = environment.endpointPath;
const logger = new Logger('auth.service');

const profileDefaults = {
  profileImagePath: 'assets/images/avatars/profile.jpg',
};

@Injectable()
export class AuthService extends BaseService {
  public userData: User;
  public cognitoResponse: any;
  onProfileChanged: BehaviorSubject<User>;

  authState: AuthState;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
    private userService: UserService,
    private amplifyService: AmplifyService
  ) {
    super();
    this.onProfileChanged = new BehaviorSubject(null);
    this.amplifyService.authStateChange$.subscribe(authState => (this.authState = authState));
  }

  private setDefaults(attr?: any): any {
    if (!attr) {
      this.userData = null;
      return this.userData;
    }

    const defaults = {
      fullName: attr.email,
      picture: attr.picture || profileDefaults.profileImagePath,
    };

    const ud = {
      ...attr,
      ...defaults,
    };

    if (ud.firstName && ud.lastName) {
      ud.fullName = `${ud.firstName} ${ud.lastName}`;
    }

    this.userData = ud;

    Sentry.configureScope(scope => {
      scope.setUser({ ...ud });
    });

    return this.userData;
  }

  public setActiveUser(user: User): void {
    logger.debug('setActiveUser invoked', user);
    this.userData = this.setDefaults(user);
    const { roles } = this.userData;
    if (!roles || !Array.isArray(roles)) {
      throw Error(`User does not have any roles assigned!\nUser: ${user}`);
    }
    const userRole = AuthService.getUserRoleFromList(roles);
    window.localStorage.setItem('xpro-user-role', userRole);
    logger.debug('setActiveUser role', userRole);
    this.onProfileChanged.next(user);
  }

  isUserVerfied(): boolean {
    return this.userData && this.userData.isVerified;
  }

  static getUserRole(): string {
    return window.localStorage.getItem('xpro-user-role');
  }

  static getUserRoleFromList(roles: string[]): string {
    if (roles.find(g => g.toLowerCase() === 'auditor')) return 'Auditor';
    if (roles.find(g => g.toLowerCase() === 'auditor admin')) return 'Auditor Admin';
    if (roles.find(g => g.toLowerCase() === 'client')) return 'Client';
    if (roles.find(g => g.toLowerCase() === 'vendor')) return 'Vendor';
    if (roles.find(g => g.toLowerCase() === 'admin')) return 'Admin';
    if (roles.find(g => g.toLowerCase() === 'potential vendor')) return 'Potential Vendor';
    if (roles.find(g => g.toLowerCase() === 'client admin')) return 'Client Admin';
    return '';
  }

  getSession(): Promise<any> {
    return this.amplifyService.auth().currentSession();
  }

  isAuthenticated(): Promise<any> {
    return this.getSession();
  }

  logout(): Observable<any> {
    return observableFrom(this.amplifyService.auth().signOut()).pipe(
      map(response => {
        window.localStorage.removeItem('xpro-user-id');
        window.localStorage.removeItem('xpro-user-role');
        this.onProfileChanged.next(null);
        this.router.navigateByUrl('/auth/login');
        return response;
      })
    );
  }

  // TODO: Farm this out to User Service and update references
  uploadProfileImage(img: any): Observable<any> {
    return observableFrom(
      this.storageService.put(`profile/${img.name}`, img, { level: 'public', ACL: 'public' }).then(r => {
        return this.storageService.getPublicPath(r['key']);
      })
    ).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  // TODO: Farm this out to User Service and update references
  updateUser(user, id): Observable<any> {
    const updatedUser = {
      ...this.userData,
      ...user,
      id,
    };
    return this.userService.updateUser(updatedUser).pipe(
      map(user => {
        this.userData = this.setDefaults(user);
        this.onProfileChanged.next(user);
        return user;
      })
    );
  }

  rememberUser(remember: boolean, email?: string) {
    window.localStorage.setItem('RememberMe', remember.toString());
    if (remember && email) {
      window.localStorage.setItem('Email', email);
    }
  }

  recallUser(): string | void {
    const userSaved = window.localStorage.getItem('RememberMe');
    if (!userSaved || userSaved !== 'true') return;
    const email = window.localStorage.getItem('Email');
    if (!email || email.length < 1) return;
    return email;
  }
}
