import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { ClientContext, ClientContextExtended, ClientFeatures } from '@core/models/client-context.model';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { ClientService } from './client.service';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private auth: AuthService, private router: Router, private clientService: ClientService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.auth
      .isAuthenticated()
      .then(r => {
        const client = ClientService.getClientFromStore();
        this.clientService.getClient(client.id).subscribe(context => this.clientService.setClientContext(context));
        return true;
      })
      .catch(err => {
        this.router.navigate(['auth', 'login'], { queryParams: { dest: state.url } });
        return false;
      });
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Promise<boolean> | Observable<boolean> {
    return this.auth
      .isAuthenticated()
      .then(r => {
        const client = ClientService.getClientFromStore();
        this.clientService.getClient(client.id).subscribe(context => this.clientService.setClientContext(context));
        return true;
      })
      .catch(err => {
        const dest = window.location.pathname.slice(1);
        this.router.navigate(['auth', 'login'], { queryParams: (dest || '').length > 0 ? { dest } : null });
        return false;
      });
  }
}

@Injectable()
export class AuditorAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return Observable.create(observer => {
      const allow = AuthService.getUserRole().toLowerCase() === 'auditor' || AuthService.getUserRole().toLowerCase() === 'auditor admin';
      if (!allow) {
        const redirect = route.data['unauthorizedRedirectUrl'] || 'apps/overview';
        this.router.navigate([redirect]);
      }
      observer.next(allow);
    });
  }
}

@Injectable()
export class ClientAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return Observable.create(observer => {
      const allow = ['Client', 'Client Admin'].includes(AuthService.getUserRole());
      if (!allow) {
        const redirect = route.data['unauthorizedRedirectUrl'] || route.root.url;
        this.router.navigate([redirect]);
      }
      observer.next(allow);
    });
  }
}

@Injectable()
export class AuditorClientAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return Observable.create(observer => {
      const allow = ['Auditor', 'Auditor Admin', 'Client', 'Client Admin'].includes(AuthService.getUserRole());
      if (!allow) {
        const redirect = route.data['unauthorizedRedirectUrl'] || 'apps/overview';
        this.router.navigate([redirect]);
      }
      observer.next(allow);
    });
  }
}

@Injectable()
export class AdminAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return Observable.create(observer => {
      const allow = AuthService.getUserRole() === 'Admin';
      if (!allow) {
        const redirect = route.data['unauthorizedRedirectUrl'] || route.root.url;
        this.router.navigate([redirect]);
      }
      observer.next(allow);
    });
  }
}

@Injectable()
export class ClientOnboardGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (AuthService.getUserRole() !== 'Client') {
      return true;
    }

    const isOnboardedVal = window.localStorage.getItem('ClientContext-isOnboarded');
    const isOnboarded = isOnboardedVal === 'true' || false;
    if (!isOnboarded && this.router.url !== '/apps/client-settings') {
      this.router.navigateByUrl('/apps/client-settings');
    }
    return isOnboarded;
  }
}

@Injectable()
export class VendorVerificationGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return Observable.create(observer => {
      this.userService.onProfileChanged.subscribe(user => {
        if (user) {
          if (UserService.getUserRoleFromList(user.roles) !== 'Vendor') {
            observer.next(true);
          } else {
            const context = ClientService.getClientFromStore();
            const shouldRedirect =
              !user.isVerified || !context || (!!context.alwaysVerifyVendor && !this.userService.verifiedSession);
            if (shouldRedirect && this.router.url !== '/apps/verification') {
              this.router.navigateByUrl('/apps/verification');
            }
            observer.next(!shouldRedirect);
          }
        }
      });
    });
  }
}

@Injectable()
export class PotentialVendorOnboardingGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.onProfileChanged.pipe(
      skipWhile(val => !val),
      map(UserService.isUserPotentialVendor)
    );
  }
}

@Injectable()
export class AuditingFeatureGuard implements CanActivate {
  constructor(private router: Router, private clientService: ClientService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.clientService.selectedContext.pipe(
      skipWhile(val => !val),
      map(featureGuard('auditing'))
    );
  }
}

@Injectable()
export class InvoicingFeatureGuard implements CanActivate {
  constructor(private router: Router, private clientService: ClientService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.clientService.selectedContext.pipe(
      skipWhile(val => !val),
      map(featureGuard('invoicing'))
    );
  }
}

@Injectable()
export class OnboardingFeatureGuard implements CanActivate {
  constructor(private router: Router, private clientService: ClientService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.clientService.selectedContext.pipe(
      skipWhile(val => !val),
      map(featureGuard('onboarding'))
    );
  }
}

export const featureGuard = (feature: keyof ClientFeatures) => (context: ClientContext | ClientContextExtended) => {
  return !!ClientService.clientHasFeatureEnabled(context, feature);
};
