import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ClientContextExtended } from '@core/models/client-context.model';
import { Invoice, NewInvoice } from '@core/models/invoice.model';
import { ESExactMatch, esGetData, ESQuery, ESResponse, ESResult, FilterParam } from '@core/models/search-models';
import { BaseService, XProResponse } from '@core/services/base.service';
import { Logger } from 'aws-amplify';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ClientService } from './client.service';
import { StorageService } from './storage.service';

const logger = new Logger('invoice.service');
const API_URL = `${environment.endpointPath}/invoices`;
const SEARCH_URL = `${environment.endpointPath}/search/invoices-index`;

@Injectable()
export class InvoicesService extends BaseService {
  clientContext: ClientContextExtended;
  selectedAuditPeriod: string;

  constructor(private http: HttpClient, private clientService: ClientService, private storageService: StorageService) {
    super();
    this.clientService.selectedContext.subscribe(context => {
      this.clientContext = context;
      this.selectedAuditPeriod = context.currentAuditPeriod;
    });
    this.clientService.selectedAuditPeriod.subscribe(ap => {
      this.selectedAuditPeriod = ap;
    });
  }

  createInvoice(invoice: NewInvoice): Observable<Invoice> {
    return this.http.post<Invoice>(API_URL, invoice);
  }

  search(
    searchText: string,
    from: number,
    size: number,
    sort?: Sort,
    filterParameters: FilterParam[] = [],
    exactMatches: ESExactMatch[] = [],
    fields: string[] = [],
    clients?: string[]
  ): Observable<ESResult<Invoice>> {
    const clientIds = clients || [...(this.clientContext.children || []).map(c => c.id), this.clientContext.id];
    const esQuery = new ESQuery(size, from)
      .addExactMatch('auditPeriod.keyword', this.selectedAuditPeriod)
      .addArrayExactMatch('clientId.keyword', clientIds)
      .addSearchParams(searchText, ['status', 'totalAmount', 'notes'])
      .addFields(fields || [])
      .addSort(sort);

    filterParameters.forEach(fp => esQuery.addArrayFilter(fp.key, fp.vals));
    exactMatches.forEach(em => esQuery.addArrayExactMatch(em.key, em.vals));

    return this.http.post<ESResponse<Invoice>>(SEARCH_URL, esQuery).pipe(map(res => esGetData(res)));
  }

  getDownloadLink(invoice: Invoice) {
    return this.http.get<XProResponse<string>>(`${API_URL}/${invoice.id}/download`).pipe(
      mergeMap(res => {
        return this.http.get(res.data, { responseType: 'blob', headers: { 'X-Skip-Interceptor': '' } });
      })
    );
  }

  updateInvoiceStatus(status: string, id: string): Observable<Invoice> {
    return this.http.put<XProResponse<Invoice>>(`${API_URL}/${id}`, { status }).pipe(map(res => res.data));
  }

  getInvoiceBadgeCount() {
    return this.search('', 0, 0, null, [], [{ key: 'status.keyword', vals: ['Sent', 'Created'] }]).pipe(
      map(res => res.total)
    );
  }
}
