import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ClientContextExtended } from '@core/models/client-context.model';
import {
  ESExactMatch,
  esGetData,
  esGetResult,
  ESQuery,
  ESResponse,
  ESResult,
  FilterParam,
} from '@core/models/search-models';
import { User, UserTemporaryPasswordSet, UserPasswordSet, UserVerificationInfo } from '@core/models/user';
import { Vendor } from '@core/models/vendor.model';
import { XproEntity } from '@core/models/xpro-entity.model';
import { BaseService, XProResponse } from '@core/services/base.service';
import { StorageService } from '@core/services/storage.service';
import * as Sentry from '@sentry/browser';
import { Cache, Logger } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthState } from 'aws-amplify-angular/src/providers/auth.state';
import { environment } from 'environments/environment';
import { BehaviorSubject, from as observableFrom, Observable, of as observableOf, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ClientService } from './client.service';
import { path } from './helpers';

const logger = new Logger('user.service');
const API_URL = `${environment.endpointPath}/users`;
const RECAPTCHA_URL = `${environment.endpointPath}/validate-recaptcha`;
const cache = Cache.createInstance({ keyPrefix: 'user.service.cache' });
const SEARCH_URL = `${environment.endpointPath}/search/users-index`;

const profileDefaults = {
  profileImagePath: 'assets/images/avatars/profile.jpg',
};

@Injectable()
export class UserService extends BaseService {
  authState: AuthState;
  clientContext: ClientContextExtended;
  onProfileChanged: BehaviorSubject<User> = new BehaviorSubject(null);
  onSelectedSearchUserChanged: BehaviorSubject<User>;
  onUserAdded: BehaviorSubject<User>;
  onUserSearchChanged: BehaviorSubject<User[]>;
  onUserUpdated: BehaviorSubject<User>;
  searchResults: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
  selectedAuditPeriod: string;
  subscription: Subscription;
  userData: User;
  verifiedSession: boolean = false;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private amplifyService: AmplifyService,
    private clientService: ClientService,
    private router: Router
  ) {
    super();
    this.amplifyService.authStateChange$.subscribe(authState => {
      this.authState = authState;
      if (authState.state.toLowerCase() === 'signedout') {
        this.onProfileChanged.next(null);
      }
      if (authState.state.toLowerCase() === 'signedin') {
        this.getLoggedInUser(authState.user.username).subscribe(user => {
          this.onProfileChanged.next(user);

          this.updateActiveUser(user).subscribe(
            user => {
              // console.log("UpdatedUser: "+JSON.stringify(user));
            },
            error => {
              // console.log("UpdatedUserError: "+JSON.stringify(error));
            }
          );
        });


      }
    });
    this.onUserAdded = new BehaviorSubject(null);
    this.onUserUpdated = new BehaviorSubject(null);
    this.subscription = this.onProfileChanged.subscribe(data => {
      if (!data) {
        cache.clear();
      } else {
        cache.setItem(data.id, data);
      }
      this.setDefaults(data);
    });

    this.clientService.selectedContext.subscribe(context => {
      if (!context) return;
      this.clientContext = context;
      this.selectedAuditPeriod = context.currentAuditPeriod;
    });
    this.clientService.selectedAuditPeriod.subscribe(ap => {
      if (!ap) return;
      this.selectedAuditPeriod = ap;
    });
  }

  private setDefaults(attr?: any): User {
    if (!attr) {
      this.userData = null;
      return this.userData;
    }

    const defaults = {
      fullName: attr.email,
      picture: attr.picture || profileDefaults.profileImagePath,
    };

    const ud = {
      ...attr,
      ...defaults,
    };

    if (ud.firstName && ud.lastName) {
      ud.fullName = `${ud.firstName} ${ud.lastName}`;
    }

    this.userData = ud;

    Sentry.configureScope(scope => {
      scope.setUser({ ...ud });
    });

    return this.userData;
  }

  public isCurrentlyLoggedInUser(user: User) {
    return path(user, 'id') === path(this.onProfileChanged, 'value', 'id');
  }

  public getUsersByClient(company: string): Observable<User[]> {
    const params = (company && { headers: {}, params: { company } }) || null;

    return this.http.get(`${API_URL}`, params).pipe(
      map(r => r['data']),
      catchError(this.handleError)
    );
  }

  public getLoggedInUser(userId: string): Observable<User> {
    logger.debug('getLoggedInUser', userId);
    const cachedUser = cache.getItem(userId);
    if (!cachedUser) {
      return this.getUserById(userId).pipe(
        map(user => {
          logger.debug('getLoggedInUser returned', user);
          cache.setItem(userId, user);
          window.localStorage.setItem('xpro-user-role', UserService.getUserRoleFromList(user.roles));
          return user;
        })
      );
    }

    window.localStorage.setItem('xpro-user-role', UserService.getUserRoleFromList(cachedUser.roles));
    logger.debug('getLoggedInUser cached', cachedUser);
    return observableOf(cachedUser);
  }

  setActiveUser = (user: User) => {
    this.onProfileChanged.next(user);
  };

  public getUserById(userId: string, clientId: string = null): Observable<User> {
    logger.info('getUserById', userId, clientId);

    let headers = {};
    if (clientId) {
      headers = this.getHeaders(<XproEntity>{ clientId });
    }
    return this.http.get(`${API_URL}/${userId}`, headers).pipe(map(r => r['data']));
  }

  public getUsers(): Observable<User[]> {
    return this.http.get(`${API_URL}`).pipe(map(r => r['data']));
  }

  public searchUsers(
    clientIds: string[] = null,
    searchTerm: string = null,
    from: number = 0,
    size: number = 10,
    sort: Sort = null,
    exactMatches: ESExactMatch[] = [],
    fields: string[] = []
  ): Observable<any> {
    const esQuery = new ESQuery(size, from);
    esQuery
      .addArrayExactMatch('clientId.keyword', clientIds)
      .addSearchParams(searchTerm, ['email', 'id', 'company', 'firstName', 'lastName', 'vendorNumbers'])
      .addSort(sort)
      .addFields(fields);

    (exactMatches || []).forEach(em => esQuery.addArrayExactMatch(em.key, em.vals));

    logger.debug('searchUsers query', esQuery);
    return this.http.post(`${environment.endpointPath}/search/users-index`, esQuery).pipe(
      map(r => {
        const result = esGetResult(r);
        this.searchResults.next(result.results);
        return result;
      })
    );
  }

  public search(
    searchTerm: string = null,
    from: number,
    size: number,
    sort?: Sort,
    filterParameters?: FilterParam[],
    exactMatches?: ESExactMatch[],
    clients?: string[]
  ): Observable<ESResult<User>> {
    const clientIds = clients || [...(this.clientContext.children || []).map(r => r.id), this.clientContext.id];
    const esQuery = new ESQuery(size, from)
      // .addExactMatch('auditPeriods.keyword', this.selectedAuditPeriod)
      .addArrayExactMatch('clientId.keyword', clientIds)
      .addSearchParams(searchTerm, ['email', 'id', 'company', 'firstName', 'lastName', 'vendorNumbers'])
      .addSort(sort);

    (filterParameters || []).forEach(fp => esQuery.addArrayFilter(fp.key, fp.vals));
    (exactMatches || []).forEach(em => esQuery.addArrayExactMatch(em.key, em.vals));

    return this.http.post<ESResponse<User>>(SEARCH_URL, esQuery).pipe(
      map(res => esGetData(res)),
      tap(res => this.searchResults.next(res.results))
    );
  }

  public createUser(user: User): Observable<User> {
    return this.http.post(`${API_URL}/`, { user }, this.getHeaders(user)).pipe(
      map(r => r['data']),
      map(data => {
        this.onUserAdded.next(data);
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public updateUser(user: User): Observable<any> {
    if (user == null) {
      return observableOf(null);
    }

    return this.http.put(`${API_URL}/${user.id}`, user, this.getHeaders(user)).pipe(
      map(r => r['data']),
      map(data => {
        this.onUserUpdated.next(data);
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public deleteUser(user: User): Observable<any> {
    if (user == null) {
      return observableOf(null);
    }

    return this.http.delete(`${API_URL}/${user.id}`, this.getHeaders(user));
  }

  public updateActiveUser(user: User): Observable<User> {
    return this.http.put(`${API_URL}/${user.id}`, user).pipe(
      map((r: { data: User }) => r.data),
      map(updatedUser => {
        this.onProfileChanged.next(updatedUser);
        return updatedUser;
      })
    );
  }

  public getUsersByVendor(vendor: Vendor): Observable<User[]> {
    return this.getUsersByVendorIdAndClientId(vendor.clientId, vendor.vendorId);
  }

  public getUsersByVendorIdAndClientId(clientId: string, vendorId: string): Observable<User[]> {
    return this.http
      .get(`${API_URL}?vendorId=${vendorId}`, this.getHeaders(<XproEntity>{ clientId }))
      .pipe(map(r => r['data']));
  }

  uploadProfileImage(img: File, userId: string): Observable<string> {
    return observableFrom(
      this.storageService.put(`profile/${userId}/${img.name}`, img, { level: 'public', ACL: 'public' }).then(r => {
        return this.storageService.getPublicPath(r['key']);
      })
    );
  }

  logout(): Observable<any> {
    return observableFrom(this.amplifyService.auth().signOut()).pipe(
      map(response => {
        window.localStorage.removeItem('xpro-user-id');
        window.localStorage.removeItem('xpro-user-role');
        this.router.navigateByUrl('/auth/login');
        return response;
      })
    );
  }

  isUserVerfied(): boolean {
    return this.userData && this.userData.isVerified;
  }

  getSession(): Promise<any> {
    return this.amplifyService.auth().currentSession();
  }

  isAuthenticated(): Promise<any> {
    return this.getSession();
  }

  static getUserRoleFromList(roleList: string[]): string {
    const roles = roleList.map(r => r.toLowerCase());
    if (roles.find(g => g === 'auditor')) return 'Auditor';
    if (roles.find(g => g === 'client')) return 'Client';
    if (roles.find(g => g === 'vendor')) return 'Vendor';
    if (roles.find(g => g === 'potential vendor')) return 'Potential Vendor';
    if (roles.find(g => g === 'admin')) return 'Admin';
    if (roles.find(g => g === 'client admin')) return 'Client Admin';
    if (roles.find(g => g === 'auditor admin')) return 'Auditor Admin';
    return '';
  }

  static getUserRole(): string {
    return window.localStorage.getItem('xpro-user-role');
  }

  static isUserRole(user: User, role: string): boolean {
    return UserService.getUserRoleFromList((user && user.roles) || []) === role;
  }

  static isUserVendor(user: User) {
    return UserService.isUserRole(user, 'Vendor');
  }

  static isUserPotentialVendor(user: User) {
    return UserService.isUserRole(user, 'Potential Vendor');
  }

  static isUserAuditor(user: User) {
    return UserService.isUserRole(user, 'Auditor');
  }

  static isUserClient(user: User) {
    return UserService.isUserRole(user, 'Client');
  }

  static isUserAdmin(user: User) {
    return UserService.isUserRole(user, 'Admin');
  }

  static isUserClientAdmin(user: User) {
    return UserService.isUserRole(user, 'Client Admin');
  }

  static isUserAuditorAdmin(user: User) {
    return UserService.isUserRole(user, 'Auditor Admin');
  }

  setTemporaryPassword(email: string,setUserTemporary: boolean): Observable<boolean> {
    const userTemporaryPasswordSet: UserTemporaryPasswordSet = {
      email,
      setUserTemporary
    };
    return this.http.post(`${API_URL}/password/set`, userTemporaryPasswordSet).pipe(
      map(value => {
        return true;
      })
    );
  }

  setNewPassword(email: string, loginToken: string, newPassword: string): Observable<boolean> {
    const userPasswordSet: UserPasswordSet = {
      email,
      loginToken,
      newPassword,
    };
    return this.http.post(`${API_URL}/password/set`, userPasswordSet).pipe(
      map(value => {
        return true;
      })
    );
  }

  verifyVendorUser(userId: string, verificationPayload): Observable<any> {
    return this.http.put(`${API_URL}/verify/${userId}`, verificationPayload).pipe(
      map(res => {
        if (!res) {
          this.verifiedSession = true;
          return;
        }
        const data = res['data'];
        this.setActiveUser(data);
        this.verifiedSession = true;
        return data;
      })
    );
  }

  verifyVendor(token: string, verificationPayload): Observable<any> {
    return this.http
      .put(`${API_URL}/verify_token/${token}`, verificationPayload, { headers: { ['X-Skip-Auth']: '' } })
      .pipe(
        map(res => {
          if (!res) {
            this.verifiedSession = true;
            return;
          }
          const data = res['data'];
          this.verifiedSession = true;
          return data;
        })
      );
  }

  uploadTaxDocumentation(vendorNumber: string, file: File): Promise<any> {
    return this.storageService.put(`vendor/${vendorNumber}/tax-documentation/${file.name}`, file, {
      level: 'public',
    });
  }

  uploadVendorCertificate(vendorNumber: string, certificateName: string, file: File): Promise<any> {
    return this.storageService.put(
      `vendor/${vendorNumber}/certificates/${certificateName.toLowerCase()}/${file.name}`,
      file,
      {
        level: 'public',
      }
    );
  }

  static isAuditor(): boolean {
    return UserService.getUserRole() === 'Auditor' || this.isAuditorAdmin(); // TODO: Make this explicit
  }

  static isAuditorAdmin(): boolean {
    return UserService.getUserRole() === 'Auditor Admin';
  }

  static isClient(): boolean {
    return UserService.getUserRole() === 'Client';
  }

  static isClientAdmin(): boolean {
    return UserService.getUserRole() === 'Client Admin';
  }

  static isVendor(): boolean {
    return UserService.getUserRole() === 'Vendor';
  }

  static isAdmin(): boolean {
    return UserService.getUserRole() === 'Admin';
  }

  exportData() {
    return this.http.get(`${API_URL}/export`).pipe(
      map(result => {
        const fileName = result['data']['filename'];
        const fileType = result['data']['content-type'];
        const fileBytesString = atob(result['data']['bytes']);
        const fileBytes = new Uint8Array(fileBytesString.split('').map(b => b.charCodeAt(0)));
        this.injectFileDownload(fileName, fileBytes, fileType);
        return true;
      })
    );
  }

  verifyToken(token: string): Observable<UserVerificationInfo> {
    return this.http
      .get(`${API_URL}/verify_token/${token}`, this.skipAuthorizationHeaders())
      .pipe(map((result: { data: UserVerificationInfo }) => result.data));
  }

  forgotPasswordCheck(email: string): Observable<boolean> {
    return this.http
      .post<XProResponse<any>>(`${API_URL}/forgot_password`, { email }, this.skipAuthorizationHeaders())
      .pipe(map(res => res && res.code === 200));
  }

  resetPassword(email: string): Observable<boolean> {
    return this.http
      .post<XProResponse<any>>(`${API_URL}/password/set`, { email }, this.skipAuthorizationHeaders())
      .pipe(map(res => res && res.code === 200));
  }

  registerPotentialVendor(email: string, password: string) {
    return this.http
      .post<XProResponse<User>>(`${API_URL}/potential`, { email, password }, this.skipAuthorizationHeaders())
      .pipe(map(r => r.data));
  }

  validateRecaptcha(token: string): Observable<boolean> {
    return this.http.post<XProResponse<any>>(RECAPTCHA_URL, { token }, this.skipAuthorizationHeaders()).pipe(
      map(_ => true),
      catchError(_ => of(false))
    );
  }

  resend(userId: string) {
    return this.http
      .post<XProResponse<User>>(`${API_URL}/${userId}/resend`, null)
      .pipe(map(res => res.data));
  }
}
