import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { fuseAnimations } from '@core/animations';
import { BaseComponent } from '@core/components/base.component';
import { PhoneNumberPipe } from '@core/pipes/phoneNumber.pipe';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'app-verify-info-dialog',
  templateUrl: './verify-info-dialog.component.html',
  styleUrls: ['./verify-info-dialog.component.scss'],
  animations: fuseAnimations,
})
export class VerifyInfoDialogComponent extends BaseComponent implements OnInit {
  btnName = 'Submit';
  editMode = true;
  phoneNumberPipe: PhoneNumberPipe;
  profileForm: FormGroup;
  profileFormErrors: any;
  profilePictureFile: any;
  profilePicturePath: string;
  user: any;
  vendorNumberCheckBox: any[];
  vendorNumberMissing: boolean = false;
  verifiedVendorNumbers: any[] = [];
  role: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    public snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<VerifyInfoDialogComponent>
  ) {
    super();
    this.phoneNumberPipe = new PhoneNumberPipe();

    const nonVendorVerifiedVendorNumbersValidator = [''];
    const vendorVerifiedVendorNumbersValidator = ['', [Validators.required]];

    this.role = AuthService.getUserRole();

    const verifiedVendorNumbers =
      this.role === 'Vendor' ? vendorVerifiedVendorNumbersValidator : nonVendorVerifiedVendorNumbersValidator;

    this.profileForm = this.formBuilder.group(
      {
        verifiedVendorNumbers,
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        companyName: ['', [Validators.required]],
        proposedVendorNumbers: [''],
        address: [''],
        streetAddress: ['', [Validators.required]],
        city: ['', [Validators.required]],
        state: ['', [Validators.required]],
        postal: ['', [Validators.required]],
        country: ['', [Validators.required]],
        faxNumber: [''],
      },
      { updateOn: 'blur' }
    );

    this.profileForm.valueChanges.subscribe(form => {
      const transformedPhone = this.phoneNumberPipe.transform(form.phoneNumber, 'us');
      const transformedFax = this.phoneNumberPipe.transform(form.faxNumber, 'us');
      if (form.phoneNumber !== transformedPhone) {
        this.profileForm.patchValue({ phoneNumber: transformedPhone });
      }
      if (form.faxNumber !== transformedFax) {
        this.profileForm.patchValue({ faxNumber: transformedFax });
      }
    });

    this.auth.onProfileChanged.subscribe(
      data => {
        if (!data) {
          this.user = null;
          return;
        }

        this.user = data;
        const att = data;
        const p = this.profileForm;
        p.get('firstName').setValue(att.firstName);
        p.get('lastName').setValue(att.lastName);
        p.get('phoneNumber').setValue(att.phone && att.phone.slice(-10));
        p.get('email').setValue(att.email);
        p.get('companyName').setValue(att.company);
        p.get('verifiedVendorNumbers').setValue(att.vendorNumbers);
        p.get('address').setValue(att.address);
        p.get('faxNumber').setValue(att.fax && att.fax.replace(/[^\w]/g, '').slice(-10));
        const vendorNumberSet = new Set([...(att.proposedVendorNumbers || []), ...(att.vendorNumbers || [])]);
        this.vendorNumberCheckBox = Array.from(vendorNumberSet);
        if (this.vendorNumberCheckBox.length < 1) {
          this.vendorNumberMissing = true;
        }
        p.get('proposedVendorNumbers').setValue(this.vendorNumberCheckBox);
        p.get('streetAddress').setValue(
          `${att.address && att.address.street1}${
            att.address && att.address.street2 ? ', '.concat(att.address.street2) : ''
          }${att.address && att.address.street3 ? ', '.concat(att.address.street3) : ''}`
        );
        p.get('city').setValue(att.address && att.address.city);
        p.get('state').setValue(att.address && att.address.state);
        p.get('postal').setValue(att.address && att.address.postal);
        p.get('country').setValue(att.address && att.address.country);
      },
      err => this.displayError(err)
    );
  }

  ngOnInit() {}

  updateUserProfile(attributes): void {
    this.auth.updateUser(attributes, this.user.id).subscribe(
      data => {
        this.snackBar.open('Account Verified', 'Close', {
          duration: 3000,
        });

        this.dialogRef.close({ name: attributes.firstName });
      },
      err => this.displayError(err)
    );
  }

  onSubmit(): void {
    this.clearError();
    const p = this.profileForm;

    const phone = p.get('phoneNumber').value.replace(/[^\d]/gim, '');
    const awsPhoneNumber = `+1${phone}`;
    const fax = p.get('faxNumber').value.replace(/[^\d]/gim, '');
    const awsFaxNumber = `+1${fax}`;
    const awsAddress = {
      city: p.get('city').value.trim(),
      state: p.get('state').value.trim(),
      postal: p.get('postal').value.trim(),
      country: p.get('country').value.trim(),
    };
    const streetAddresses = p
      .get('streetAddress')
      .value.trim()
      .split(', ');
    if (streetAddresses && streetAddresses.length > 0) {
      streetAddresses.forEach((e, i) => (awsAddress[`street${i + 1}`] = e));
    }

    const attributes = {
      firstName: p.get('firstName').value,
      lastName: p.get('lastName').value,
      email: p.get('email').value,
      phone: awsPhoneNumber,
      company: p.get('companyName').value,
      vendorNumbers: p.get('verifiedVendorNumbers').value,
      proposedVendorNumbers: this.vendorNumberCheckBox,
      address: awsAddress,
      fax: awsFaxNumber,
      isVerified: true,
    };
    this.updateUserProfile(attributes);
  }

  onChangeOfVendorNumber(inputValue, event) {
    const vendorNumber = inputValue.trim();
    if (event.checked) {
      this.verifiedVendorNumbers.push(vendorNumber);
    } else {
      const index = this.verifiedVendorNumbers.findIndex(x => x === vendorNumber);
      if (index < 0) return;
      this.verifiedVendorNumbers.splice(index, 1);
    }

    this.profileForm.get('verifiedVendorNumbers').setValue(this.verifiedVendorNumbers);
    this.profileForm.get('verifiedVendorNumbers').updateValueAndValidity({ emitEvent: true });
  }

  vendorNumberMissingChangeHandler = event => {
    this.profileForm.get('verifiedVendorNumbers').setValue(event.checked ? 'missing' : null);
    this.profileForm.get('verifiedVendorNumbers').updateValueAndValidity({ emitEvent: true });
  };
}
