import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientContextExtended } from '@core/models/client-context.model';
import { ClientService } from '@core/services/client.service';
import { Auth, Logger } from 'aws-amplify';
import { from as observableFrom, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const logger = new Logger('token.interceptor');

export const ClientHeader = 'xpro-client'; // tslint:disable-line
export const AuthorizationHeader = 'Authorization'; // tslint:disable-line
export const AmazonSecurityToken = 'x-amz-security-token'; // tslint:disable-line
export const InterceptorSkipHeader = 'X-Skip-Interceptor'; //tslint:disable-line
export const InterceptorSkipAuth = 'X-Skip-Auth'; //tslint:disable-line

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  clientContext: ClientContextExtended;

  constructor(private clientService: ClientService) {
    this.clientService.selectedContext.subscribe(context => {
      if (!context) return;
      this.clientContext = context;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Ignore presigned URL request
    const paramString = ((request && request.urlWithParams) || '?').split('?')[1];
    if (paramString && paramString.toLowerCase().includes(AmazonSecurityToken)) return next.handle(request);

    // Ignore if skip is requested
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    let clientContextId = null;
    if (request.headers.has(ClientHeader)) {
      clientContextId = request.headers.get(ClientHeader);
    } else if (this.clientContext) {
      clientContextId = this.clientContext.id;
    } else {
      clientContextId = localStorage.getItem('ClientContext-id');
    }

    if (request.headers.has(InterceptorSkipAuth)) {
      const headers = request.headers.delete(InterceptorSkipAuth).append(ClientHeader, clientContextId);
      return next.handle(request.clone({ headers }));
    }

    if (request.url.indexOf('/users/password/set') > -1) {
      const headersObject = {};
      headersObject[ClientHeader] = clientContextId;

      const headers = new HttpHeaders(headersObject);
      const newRequest = request.clone({
        headers,
      });
      logger.debug('Intercepted HTTP call and added headers: ', newRequest);
      return next.handle(newRequest);
    }

    return observableFrom(Auth.currentSession()).pipe(
      switchMap(session => {
        const token = session.getIdToken().getJwtToken();

        const headersObject = {
          Authorization: token.startsWith('Bearer ') ? token : `Bearer ${token}`,
        };

        headersObject[ClientHeader] = clientContextId;

        const headers = new HttpHeaders(headersObject);
        const newRequest = request.clone({
          headers,
        });

        logger.debug('Intercepted HTTP call and added headers: ', newRequest);
        return next.handle(newRequest);
      })
    );
  }
}
