import { Pipe, PipeTransform } from '@angular/core';
import { default as PhoneNumber } from 'awesome-phonenumber';

@Pipe({ name: 'phone' })
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string, countryCode: string = 'us') {
    if (!value) return '';
    const numericValue = value.replace(/[^\d]/gim, '');
    const trimmedValue = countryCode !== 'us' || numericValue.length <= 15 ? numericValue : numericValue.slice(0, 15);
    const formatter = PhoneNumber.getAsYouType(countryCode);
    formatter.reset(trimmedValue);

    const json = formatter.getPhoneNumber().toJSON();
    return (json && json.number && json.number.input) || trimmedValue;
  }
}
