import { Component } from '@angular/core';
import { FuseSplashScreenService } from '@core/services/splash-screen.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@core/services/translation-loader.service';

import { locale as navigationEnglish } from './navigation/i18n/en';
import { locale as navigationTurkish } from './navigation/i18n/tr';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'fuse-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private fuseSplashScreen: FuseSplashScreenService,
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // Add languages
    this.translate.addLangs(['en', 'tr']);

    // Set the default language
    this.translate.setDefaultLang('en');

    // Use a language
    this.translate.use('en');

    // Set the navigation translations
    this.translationLoader.loadTranslations(navigationEnglish, navigationTurkish);

    // register Icons
    // ToDo will move to other module
    this.registerXproIcons();
  }

  // ToDo: Maybe not required any more.
  registerXproIcons() {
    this.matIconRegistry.addSvgIcon(
      'txt',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file-icons/txt.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'cloudDownload',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom-icons/download.svg')
    );
  }
}
