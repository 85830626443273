import { enableProdMode } from '@angular/core';
import { environment } from '@environments/environment';
import awsAmplify, { Logger } from 'aws-amplify';
import { hmrBootstrap } from 'hmr';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
const logger = new Logger('main');
if (environment.production) {
    awsAmplify.Logger.LOG_LEVEL = 'ERROR';
    enableProdMode();
}
else {
    awsAmplify.Logger.LOG_LEVEL = 'DEBUG';
}
awsAmplify.configure(environment.aws);
const bootstrap = () => __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
const ɵ0 = bootstrap;
if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    }
    else {
        logger.error('HMR is not enabled for webpack-dev-server!');
        logger.info('Are you using the --hmr flag for ng serve?');
    }
}
else {
    bootstrap().catch(err => console.log('BOOTSTRAP ERROR: ', err)); // tslint:disable-line
}
export { ɵ0 };
