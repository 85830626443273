import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { SharedModule } from '@core/modules/shared.module';
import { AuthService } from '@core/services/auth.service';
import { AuthGuard } from '@core/services/authguard.service';
import { ClientService } from '@core/services/client.service';
import { FuseConfigService } from '@core/services/config.service';
import { FileUploadService } from '@core/services/fileUpload.service';
import { InvoicesService } from '@core/services/invoices.service';
import { FuseSplashScreenService } from '@core/services/splash-screen.service';
import { StorageService } from '@core/services/storage.service';
import { UserService } from '@core/services/user.service';
import { environment } from '@environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { AmplifyService } from 'aws-amplify-angular';
import 'hammerjs';
import { RecaptchaModule } from 'ng-recaptcha';
import { AppComponent } from './app.component';
import { AppResolver } from './app.resolver';

Sentry.init({
  dsn: 'https://e01280fe8e0444b4be0e50f755b2e55d@sentry.io/1232312',
  beforeSend(event) {
    if (!environment.production) return null;
    return event;
  },
});
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.production) {
      const eventId = Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }
}

export const appRoutes: Routes = [
  {
    path: '',
    resolve: {
      companyContext: AppResolver,
    },
    children: [
      {
        path: 'apps',
        canLoad: [AuthGuard],
        loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),
      },
      {
        path: 'auth',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
      },
      {
        path: 'errors',
        loadChildren: () => import('./errors/404/error-404.module').then(m => m.Error404Module),
      },
      {
        path: '**',
        redirectTo: 'apps',
      },
    ],
  },
];

export const routerModuleForRoot = RouterModule.forRoot(appRoutes);
export const translateModule = TranslateModule.forRoot();
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RecaptchaModule,
    translateModule,
    routerModuleForRoot,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    FuseSplashScreenService,
    FuseConfigService,
    AuthService,
    UserService,
    StorageService,
    AuthGuard,
    AppResolver,
    ClientService,
    FileUploadService,
    AmplifyService,
    InvoicesService,
    { provide: ErrorHandler, useClass: environment.production ? SentryErrorHandler : ErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
