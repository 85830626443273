import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import {
  ClientContext,
  ClientContextExtended,
  ClientFeatures,
  ClientOnboardingConfig
} from '@core/models/client-context.model';
import { esGetResult, ESQuery } from '@core/models/search-models';
import { BaseService, XProResponse } from '@core/services/base.service';
import { path } from '@core/services/helpers';
import { Logger } from 'aws-amplify';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const API_URL = `${environment.endpointPath}/clients`;
const logger = new Logger('client.service');
const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';

@Injectable()
export class ClientService extends BaseService {
  onChanged: BehaviorSubject<ClientContextExtended> = new BehaviorSubject<ClientContextExtended>(null);
  onClientAdded: BehaviorSubject<ClientContext>;
  onClientUpdated: BehaviorSubject<ClientContext>;
  selectedAuditPeriod: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  selectedContext: BehaviorSubject<ClientContextExtended> = new BehaviorSubject<ClientContextExtended>(null);

  constructor(private http: HttpClient) {
    super();
    this.onClientAdded = new BehaviorSubject(null);
    this.onClientUpdated = new BehaviorSubject(null);
  }

  public getClient(id: string): Observable<ClientContextExtended> {
    return this.http.get<XProResponse<ClientContextExtended>>(`${API_URL}/${id}`).pipe(
      map(r => r.data),
      catchError(this.handleError)
    );
  }

  public getOnboardingConfiguration(id: string): Observable<ClientOnboardingConfig> {
    return this.http
      .get<XProResponse<ClientOnboardingConfig>>(`${API_URL}/onboarding-configuration/${id}`)
      .pipe(map(res => res.data));
  }

  public setClientContext(context: ClientContext): void {
    const oldContext = ClientService.getClientFromStore();
    window.localStorage.setItem('ClientContext', JSON.stringify({ ...oldContext, ...context }));
    Object.keys(context).forEach(k => window.localStorage.setItem(`ClientContext-${k}`, context[k]));
    this.onChanged.next({ ...oldContext, ...context });
    this.selectedContext.next({ ...oldContext, ...context });
    this.selectedAuditPeriod.next(context.currentAuditPeriod);
  }

  public getClientBySubdomain(subdomain: string): Observable<ClientContext> {
    const headers = new HttpHeaders({ [INTERCEPTOR_SKIP_HEADER]: '' });
    logger.debug('This is headers: ', headers);
    logger.debug('This is api & subdomain url: ', `${API_URL}?subdomain=${subdomain}`);
    const results = this.http.get<ClientContext>(`${API_URL}?subdomain=${subdomain}`, { headers }).pipe(
      map(response => response['data'][0]),
      catchError(this.handleError)
    );
    logger.debug('Results: ', results)
    return results
  }

  public subdomainExists(subdomain: string): Observable<boolean> {
    const headers = new HttpHeaders({ [INTERCEPTOR_SKIP_HEADER]: '' });
    return this.http.get<ClientContext>(`${API_URL}?subdomain=${subdomain}`, { headers }).pipe(
      map(c => {
        return true;
      }),
      catchError(err => {
        // We'll default to false and let the API bark when the subdomain exists
        return of(false);
      })
    );
  }

  static getClientFromStore(): ClientContextExtended {
    return JSON.parse(window.localStorage.getItem('ClientContext') || '{}');
  }

  public getClients(): Observable<ClientContext[]> {
    const headers = new HttpHeaders({ [INTERCEPTOR_SKIP_HEADER]: '' });
    return this.http.get(`${API_URL}`, { headers }).pipe(map(response => response['data']));
  }

  public createClient(client: ClientContext): Observable<any> {
    return this.http.post(`${API_URL}/`, { client }).pipe(
      map(r => r['data']),
      map(data => {
        this.onClientAdded.next(data);
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public updateClient(client: ClientContextExtended, reportUpdated: boolean = true): Observable<any> {
    return this.http.put(`${API_URL}/${client.id}`, client).pipe(
      map(r => r['data']),
      map(data => {
        if (reportUpdated) {
          this.onClientUpdated.next(data);
        }
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public searchClients(
    searchTerm: string = null,
    from: number,
    size: number,
    sort: Sort = null,
    fields: string[] = []
  ): Observable<any> {
    const esQuery = new ESQuery(size, from);
    esQuery
      .addSearchParams(searchTerm, ['id', 'name', 'hostname'])
      .addSort(sort)
      .addFields(fields);

    logger.info('searchClients query', esQuery);
    return this.http.post(`${environment.endpointPath}/search/clients-index`, esQuery).pipe(
      map(r => {
        const result = esGetResult(r);
        return result;
      })
    );
  }

  changeSelectedClientContext(id: string): Observable<ClientContextExtended> {
    const currentContext = ClientService.getClientFromStore();
    console.log('Current Context: ', currentContext);
    const valid = id === currentContext.id || !!(currentContext.children || []).find(e => e.id === id) || !!(currentContext.parent || []).find(e => e.id === id);
    console.log('Valid: ', valid);
    if (!valid) return;
    if (id === currentContext.id) {
      return of(currentContext).pipe(
        map(context => {
          this.selectedContext.next(context);
          return context;
        })
      );
    }
    return this.getClient(id).pipe(
      map(context => {
        this.selectedContext.next(context);
        return context;
      })
    );
  }

  changeSelectedAuditPeriod(period: string) {
    this.selectedAuditPeriod.next(period);
  }

  static isAuditPeriodActive(auditPeriod: string, context: ClientContext) {
    if (!auditPeriod || !context || !context.currentAuditPeriod) {
      return false;
    }
    return context.currentAuditPeriod === auditPeriod;
  }

  static clientHasFeatureEnabled(
    context: ClientContext | ClientContextExtended,
    key: keyof ClientFeatures | keyof ClientOnboardingConfig
  ): boolean {
    if (!context) {
      logger.warn('context is required to determine feature eligibility');
      return false;
    }
    return ClientService.featureIsEnabled(context.features, key);
  }

  static featureIsEnabled(features: ClientFeatures, key: keyof ClientFeatures | keyof ClientOnboardingConfig): boolean {
    if (!features) {
      logger.warn('features is required to determine feature eligibility');
      return false;
    }

    let enabled = false;

    if (!features || !key) return false;

    switch (key) {
      case 'onboarding':
      case 'verifying':
        enabled = features[key] && features[key].enabled;
        break;
      case 'generateVendorNumbers':
        enabled = !!path(features, 'onboarding', key, 'enabled');
        break;
      case 'certificates':
        enabled = features[key].diversity;
        break;
      case 'vendorVerification':
        enabled = features[key].taxForms.enabled;
        break;
      default:
        enabled = features[key];
    }

    return enabled;
  }
}
