import { ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@core/services/authguard.service';
import { environment } from '@environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import 'hammerjs';
import { AppResolver } from './app.resolver';
Sentry.init({
    dsn: 'https://e01280fe8e0444b4be0e50f755b2e55d@sentry.io/1232312',
    beforeSend(event) {
        if (!environment.production)
            return null;
        return event;
    },
});
export class SentryErrorHandler {
    constructor() { }
    handleError(error) {
        if (environment.production) {
            const eventId = Sentry.captureException(error.originalError || error);
        }
        else {
            console.error(error);
        }
    }
}
const ɵ0 = () => import("./apps/apps.module.ngfactory").then(m => m.AppsModuleNgFactory), ɵ1 = () => import("./authentication/authentication.module.ngfactory").then(m => m.AuthenticationModuleNgFactory), ɵ2 = () => import("./errors/404/error-404.module.ngfactory").then(m => m.Error404ModuleNgFactory);
export const appRoutes = [
    {
        path: '',
        resolve: {
            companyContext: AppResolver,
        },
        children: [
            {
                path: 'apps',
                canLoad: [AuthGuard],
                loadChildren: ɵ0,
            },
            {
                path: 'auth',
                loadChildren: ɵ1,
            },
            {
                path: 'errors',
                loadChildren: ɵ2,
            },
            {
                path: '**',
                redirectTo: 'apps',
            },
        ],
    },
];
export const routerModuleForRoot = RouterModule.forRoot(appRoutes);
export const translateModule = TranslateModule.forRoot();
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2 };
