// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const IDENTITY_POOL_ID = 'us-east-1:00b3ce42-9f17-4e70-8fba-d2396ba54129';
const REGION = 'us-east-1';
const ENDPOINT_NAME = 'xpro-api';
const ENDPOINT_PATH = 'https://dev-xpro-api.xproaudit.com';
const RECAPTCHA_SITEKEY = '6LcglMIUAAAAAIunOEqlsy89hJio7nDp6xXaIzof';

export const environment = {
  production: false,
  hmr: false,
  apiBaseUrl: 'api',
  endpointName: ENDPOINT_NAME,
  endpointPath: ENDPOINT_PATH,
  aws: {
    Auth: {
      identityPoolId: IDENTITY_POOL_ID,
      region: REGION,
      userPoolId: 'us-east-1_6de7E8hhe',
      userPoolWebClientId: '7s9apao3641valdn5m0tccfl3l',
    },
    Storage: {
      identityPoolId: IDENTITY_POOL_ID,
      bucket: 'xpro-dev-data',
      region: REGION,
    },
    API: {
      endpoints: [
        {
          name: ENDPOINT_NAME,
          endpoint: ENDPOINT_PATH,
        },
      ],
      aws_appsync_graphqlEndpoint: 'https://uqxezukv6vfblb3zqx4rpzwxm4.appsync-api.us-east-1.amazonaws.com/graphql',
      aws_appsync_region: REGION,
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    },
    Analytics: {
      disabled: true,
    },
  },
  recaptcha: {
    sitekey: RECAPTCHA_SITEKEY,
  },
};
