import { AbstractControl } from '@angular/forms';
import { v4 as UUID } from 'uuid';

export type SecureFile = Blob & File;

export const secureRenameFile = (file: File): SecureFile => {
  const filename = `${UUID()}__${file.name.toLowerCase()}`;
  const fileSlice = file.slice(0, file.size, file.type);
  if (!navigator.msSaveBlob) {
    return new File([fileSlice], filename, { type: file.type });
  }
  const blob = new Blob([fileSlice], { type: file.type });
  blob['name'] = filename;
  return <SecureFile>blob;
};

export const getUniqueIdentifier = (prefix?: string): string => {
  if (prefix) {
    return `${prefix}${UUID()}`;
  }
  return `${UUID()}`;
};

/* tslint:disable:max-line-length */
// prettier-ignore
export function path<T, A extends keyof NonNullable<T>>(obj: T, propA: A): NonNullable<T>[A] | undefined;
// prettier-ignore
export function path<T, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>>(obj: T, propA: A, propB: B): NonNullable<NonNullable<T>[A]>[B] | undefined;
// prettier-ignore
export function path<T, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>>(obj: T, propA: A, propB: B, propC: C): NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C] | undefined;
// prettier-ignore
export function path<T, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>>(obj: T, propA: A, propB: B, propC: C, propD: D): NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D] | undefined;
// prettier-ignore
export function path<T, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>, E extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>>(obj: T, propA: A, propB: B, propC: C, propD: D, propE: E): NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E] | undefined;
// prettier-ignore
export function path<T, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>, E extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>, F extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>>(obj: T, propA: A, propB: B, propC: C, propD: D, propE: E, propF: F): NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>[F] | undefined;
// prettier-ignore
export function path<T, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>, E extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>, F extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>, G extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>[F]>>(obj: T, propA: A, propB: B, propC: C, propD: D, propE: E, propF: F, propG: G): NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>[F]>[G] | undefined;
// prettier-ignore
export function path<T, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>, E extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>, F extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>, G extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>[F]>>(obj: T, propA: A, propB: B, propC: C, propD: D, propE: E, propF: F, propG: G, ...props: any[]): NonNullable<T>[any] | undefined;
/* tslint:enable:max-line-length */
export function path(obj: any, ...props: string[]): any {
  return obj && (props || []).reduce((acc, k) => (!acc ? undefined : acc[k]), obj);
}

/* tslint:disable:max-line-length */
// prettier-ignore
export function pathOr<T, Z, A extends keyof NonNullable<T>>(obj: T, _: Z, propA: A): NonNullable<T>[A] | Z;
// prettier-ignore
export function pathOr<T, Z, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>>(obj: T, _: Z, propA: A, propB: B): NonNullable<NonNullable<T>[A]>[B] | Z;
// prettier-ignore
export function pathOr<T, Z, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>>(obj: T, _: Z, propA: A, propB: B, propC: C): NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C] | Z;
// prettier-ignore
export function pathOr<T, Z, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>>(obj: T, _: Z, propA: A, propB: B, propC: C, propD: D): NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D] | Z;
// prettier-ignore
export function pathOr<T, Z, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>, E extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>>(obj: T, _: Z, propA: A, propB: B, propC: C, propD: D, propE: E): NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E] | Z;
// prettier-ignore
export function pathOr<T, Z, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>, E extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>, F extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>>(obj: T, _: Z, propA: A, propB: B, propC: C, propD: D, propE: E, propF: F): NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>[F] | Z;
// prettier-ignore
export function pathOr<T, Z, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>, E extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>, F extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>, G extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>[F]>>(obj: T, _: Z, propA: A, propB: B, propC: C, propD: D, propE: E, propF: F, propG: G): NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>[F]>[G] | Z;
// prettier-ignore
export function pathOr<T, Z, A extends keyof NonNullable<T>, B extends keyof NonNullable<NonNullable<T>[A]>, C extends keyof NonNullable<NonNullable<NonNullable<T>[A]>[B]>, D extends keyof NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>, E extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>, F extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>, G extends keyof NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<T>[A]>[B]>[C]>[D]>[E]>[F]>>(obj: T, _: Z, propA: A, propB: B, propC: C, propD: D, propE: E, propF: F, propG: G, ...props: any[]): NonNullable<T>[any] | Z;
/* tslint:enable:max-line-length */
export function pathOr(obj: any, defaultValue: any, ...props: string[]): any {
  return (obj && (props || []).reduce((acc, k) => (!acc ? undefined : acc[k]), obj)) || defaultValue;
}

export function currencyFormatter(n: number | string): string {
  const float = parseFloat(n.toString()).toFixed(2);
  const str = float.slice(0, -3);
  const decimal = float.slice(-2);
  if (str.length < 5) return `$${str}.${decimal}`;
  return `$${str
    .split('')
    .reverse()
    .reduce((acc, d) => {
      if (acc[0] && acc[0].length < 3) return [`${d}${acc[0]}`, ...acc.slice(1)];
      return [d, ...acc];
    }, [])
    .join(',')}.${decimal}`;
}

export function dateFormatter(
  options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' }
) {
  return function(epoch: number) {
    return new Date(epoch).toLocaleDateString('en-US', options);
  };
}

export function hasUppercase(chars: string) {
  return chars.split('').reduce((acc, c) => acc || c === c.toUpperCase(), false);
}
export function hasLowercase(chars: string) {
  return chars.split('').reduce((acc, c) => acc || c === c.toLowerCase(), false);
}
export function hasNumber(chars: string) {
  // @ts-ignore
  return chars.split('').reduce((acc, c) => acc || !isNaN(c), false);
}
export function hasSpecial(chars: string) {
  return chars.split('').reduce((acc, c) => acc || '!@#$%^&*()'.includes(c), false);
}
export function passwordMatchValidator(control: AbstractControl) {
  if (!control.parent || !control.parent.get('password')) return;
  const { value: passwordInput } = control.parent.get('password');
  if (control.value !== passwordInput) {
    return { passwordMatch: true };
  }
  return;
}
export const matchingValidator = (compare: AbstractControl) => (control: AbstractControl) => {
  if (!compare) return;
  if (control.value !== compare.value) {
    return { passwordMatch: true };
  }
};
export function passwordValidator(control: AbstractControl) {
  const pw: string = control.value;
  const valid = hasUppercase(pw) && hasLowercase(pw) && hasNumber(pw) && hasSpecial(pw);
  if (valid) return null;
  return { validPassword: true };
}

export const passwordErrorSelector = (control: AbstractControl) => {
  if (!!control.getError('required')) return 'Please enter a password.';
  if (control.value.length < 8) return 'Your password must be at least 8 characters long.';
  if (!!control.getError('validPassword')) {
    return `Your password needs to include uppercase and lowercase letters, at least one number, and at least 1 special character (!@#$%^&*())`;
  }
};

export type Zip<T extends unknown[][]> = { [I in keyof T]: T[I] extends (infer U)[] ? U : never }[];
export function zip<T extends unknown[][]>(...args: T): Zip<T> {
  const maxLength = Math.min(args[0].length, args[1].length);
  return <Zip<T>>(<unknown>args[0].map((_, c) => args.map(row => row[c])).slice(0, maxLength));
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
