import { Sort } from '@angular/material/sort';
import { EmailConfig } from '@core/models/client.model';
import { Address } from '@core/models/user';
import { VendorVerificationRequest } from '@core/models/vendor-verification-request.model';
import { PotentialVendor, VendorMasterUser } from '@core/models/vendor.model';
import { path } from '@core/services/helpers';

export class SearchClient {
  id: string;
  name: string;
  subdomain: string;
}

export class SearchClientDetails {
  id: string;
  name: string;
  subdomain: string;
  website: string;
  logo: string;
  logoThumb: string;
  currentAuditPeriod?: string;
  emailConfig: EmailConfig;

  constructor() {
    this.id = '';
    this.name = '';
    this.subdomain = '';
    this.website = '';
    this.logo = '';
    this.logoThumb = '';
    this.currentAuditPeriod = '';
    this.emailConfig = new EmailConfig();
  }
}

export class SearchAddress {
  street1: string;
  street2?: string;
  street3?: string;
  city: string;
  state: string;
  postal: string;
  country: string;
}

export class SearchUser {
  address?: SearchAddress;
  clientId: string;
  client: SearchClient;
  company: string;
  email: string;
  fax: string;
  firstLoginTime: number;
  firstName: string;
  id: string;
  isVerified: boolean;
  lastLoginTime: number;
  lastName: string;
  phone: string;
  profilePictureUrl: string;
  proposedVendorNumbers: string[];
  roles: ('auditor' | 'client' | 'vendor' | 'admin')[];
  status?: string;
  title: string;
  vendorNumbers: string[];
}

export type SearchVendorVerificationRequest = VendorVerificationRequest & {
  client: SearchClient;
};

export type SearchPotentialVendor = PotentialVendor & {
  client: SearchClient;
  email: string;
  createdBy: string;
  lastLoginTime?: number;
};

export class SearchPagingInfo {
  totalResults: number;
  from: number;
  size: number;

  constructor() {
    this.totalResults = 0;
    this.from = 0;
    this.size = 10;
  }
}

export class SearchVendorDetails {
  client: SearchClient;
  companyInformation: { address: Address; company: string; phone: string; taxId: string };
  lastContacted?: number;
  status: 'Sent' | 'Verified' | 'Unverified' | 'Needs Investigation' | 'Left Voicemail' | 'Non-responsive';
  users: VendorMasterUser[];
  vendorId: string;
  clientId: string;
}

class SearchParams {
  searchTerm: string;
  fields: string[];
}

export type ESResult<T> = {
  total: number;
  results: T[];
};

export class FilterParam {
  key: string;
  vals: string[];

  constructor(key: string, vals: string[]) {
    this.key = key;
    this.vals = vals;
  }
}

export type ESExactMatch = {
  key: string;
  vals: (string | number | boolean)[];
};

export type ESAggregateDocCount = {
  doc_count: number;
};

export type ESOnboardingAggregations = {
  awaiting_approval: ESAggregateDocCount;
  awaiting_response: ESAggregateDocCount;
  incomplete_vendors: ESAggregateDocCount;
  rejected: ESAggregateDocCount;
};

export type ESVerifyingAggregations = {
  verified: ESAggregateDocCount;
  unverified: ESAggregateDocCount;
  investigate: ESAggregateDocCount;
};

export type ESOnboardingAggsResponse<T> = {
  code: number;
  data: {
    aggregations: ESOnboardingAggregations;
    took: number;
    timed_out: boolean;
    _shards: {
      total: number;
      successful: number;
      skipped: number;
      failed: number;
    };
    hits: {
      total: number;
      max_score: number;
      hits: {
        _index: string;
        _type: string;
        _id: string;
        _score: number;
        _source: T;
      }[];
    };
  };
};

export type ESVerifyingAggsResponse<T> = {
  code: number;
  data: {
    aggregations: ESVerifyingAggregations;
    took: number;
    timed_out: boolean;
    _shards: {
      total: number;
      successful: number;
      skipped: number;
      failed: number;
    };
    hits: {
      total: number;
      max_score: number;
      hits: {
        _index: string;
        _type: string;
        _id: string;
        _score: number;
        _source: T;
      }[];
    };
  };
};

export type ESResponse<T> = {
  code: number;
  data: {
    took: number;
    timed_out: boolean;
    _shards: {
      total: number;
      successful: number;
      skipped: number;
      failed: number;
    };
    hits: {
      total: number;
      max_score: number;
      hits: {
        _index: string;
        _type: string;
        _id: string;
        _score: number;
        _source: T;
      }[];
    };
  };
};

export type ESSingleResponse<T> = {
  code: number;
  data: {
    found: boolean;
    _id: string;
    _index: string;
    _source: T;
    _type: string;
  };
};

export class ESQuery {
  size: number;
  start: number;
  search: any;
  exactMatches: any[];
  filters: any[];
  sort: any[];
  raw: any;
  fields: string[];

  constructor(size: number = 0, start: number = 10) {
    this.size = size;
    this.start = start;
    this.search = null;
    this.exactMatches = [];
    this.filters = [];
    this.sort = [];
    this.raw = null;
    this.fields = [];
  }

  public addExactMatch(key: string, val: string): ESQuery {
    if (val && key) {
      this.exactMatches.push({ [key]: val });
    }
    return this;
  }

  public addArrayExactMatch(key: string, val: (string | any)[]): ESQuery {
    if (val && val.length > 0 && key) {
      this.exactMatches.push({ [key]: val });
    }
    return this;
  }

  public addSearchParams(searchTerm: string, fields: string[]): ESQuery {
    if (searchTerm) {
      this.search = { searchTerm, fields };
    }
    return this;
  }

  public addFilter(key: string, val: string): ESQuery {
    if (key && val) {
      this.filters.push({ term: { [key]: val } });
    }
    return this;
  }

  public addArrayFilter(key: string, val: string[]): ESQuery {
    if (key && val && val.length > 0) {
      this.filters.push({ terms: { [key]: val } });
    }
    return this;
  }

  public addSort(sort: Sort): ESQuery {
    if (!(sort && sort.direction)) return this;

    let field = sort.active;
    const sortProperties = {
      order: sort.direction,
    };

    if (field.split(':').length === 2) {
      field = field.split(':')[0];
      const mode = sort.active.split(':')[1];
      sortProperties['mode'] = mode;
    }

    this.sort.push({ [field]: sortProperties });
    return this;
  }

  public addFields(fields: string[]): ESQuery {
    this.fields.push(...fields);
    return this;
  }
}

export const esHasHits = (payload: any) => {
  return payload && payload['data'] && payload['data']['hits'] && payload['data']['hits']['hits'];
};

export const esGetResult = (payload: any): ESResult<any> => {
  if (esHasHits(payload)) {
    return {
      total: payload['data']['hits']['total'],
      results: payload['data']['hits']['hits'].map(u => {
        return { _id: u._id, ...u._source };
      }),
    };
  }
  return {
    total: 0,
    results: [],
  };
};

export const esGetSingleResult = (payload: any): any => {
  if (payload && payload['data'] && payload['data']['_source']) {
    return payload['data']['_source'];
  }
  return null;
};

export const esGetData = <T>(response: ESResponse<T>): ESResult<T> => {
  const results = (path(response, 'data', 'hits', 'hits') || []).map(e => e._source);
  const total = path(response, 'data', 'hits', 'total') || 0;
  return { results, total };
};
